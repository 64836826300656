.program-details-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #F6AE4B; /* Yellow accent */
    color: white;
    text-decoration: none;
    border-radius: 4px;
    margin-bottom: 20px;
}

.button:hover {
    background-color: #555555; /* Darker hover effect */
}

.program-section {
    background-color: #f7f7f7; /* Light gray background for program section */
    border: 1px solid #dddddd;
    padding: 15px;
    margin-bottom: 30px;
    border-radius: 8px;
}

.program-section h3 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 15px;
}

.program-section h4 {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 10px;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
}

.custom-table th,
.custom-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.custom-table thead {
    background-color: #F6AE4B; /* Yellow accent for the table header */
    color: white;
}

.custom-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for alternating rows */
}

.custom-table tbody tr:nth-child(odd) {
    background-color: #eeeeee; /* Slightly darker light gray for odd rows */
}

.custom-table tbody tr:hover {
    background-color: #f1f1f1; /* Hover effect for table rows */
}

.custom-table th[colspan="2"] {
    text-align: center; /* Center text for merged cells */
    background-color: #333333; /* Dark gray background for merged cells */
    font-size: 1.2rem; /* Adjust font size for workout number */
    color: white;
}

.custom-table th {
    text-align: center; /* Center the text in headers */
}

.custom-table td {
    text-align: center; /* Center the text in data cells */
}

/* Explicit column widths for Equipment and Exercise columns */
.custom-table th.equipment,
.custom-table td.equipment {
    width: 150px; /* Set a fixed width for the Equipment column */
}

.custom-table th.exercise,
.custom-table td.exercise {
    width: 300px; /* Fixed width for Exercise column */
    text-align: left; /* Align exercise name to the left */
    font-weight: bold; /* Make the exercise name bold */
}

.print-button {
    background-color: #F6AE4B; /* Yellow accent */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
  }
  
  .print-button:hover {
    background-color: #333333; /* Darker hover effect */
  }
  
  .program-header {
    display: flex;
    justify-content: space-between; /* Spread program details and print button */
    align-items: center; /* Vertically align items */
    margin-bottom: 20px; /* Add some space below */
  }
  
  .program-details {
    max-width: 70%; /* Keep the details from taking up too much space */
  }
  
  .print-button {
    background-color: #F6AE4B; /* Yellow accent */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .print-button:hover {
    background-color: #333333; /* Darker hover effect */
  }
  @media print {
    /* General print adjustments */
    body {
      margin: 0;
      padding: 0;
    }
  
    /* Ensure mesocycle and workouts are printed on the same page */
    .program-section {
      page-break-inside: avoid;
    }
  
    .workout-container {
      page-break-inside: avoid;
    }
  
    /* Adjust the font size and padding to fit more content */
    .custom-table {
      font-size: 12px;
    }
  
    .custom-table th,
    .custom-table td {
      padding: 5px;
    }
  
    .sets-header,
    .reps-header,
    .sets-data,
    .reps-data {
      width: 40px;
    }
  
    .equipment,
    .exercise {
      width: 100px;
    }
  
    /* Adjust the week header font and padding to prevent stacking */
    .week-header {
      font-size: 10px;  /* Reduced font size */
      padding: 3px 5px; /* Reduce padding to prevent stacking */
      width: 80px;      /* Set a minimum width to prevent content from collapsing */
      text-align: center;
    }
  
    /* Suggest landscape mode for better horizontal space */
    @page {
      size: landscape;
    }
  
    /* Hide unnecessary elements */
    .print-button {
      display: none;
    }
  }
  
  