/* ProgramDatabase.css */

.program-list-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .program-card {
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .program-card h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .program-card p {
    font-size: 14px;
    color: #555;
    margin-bottom: 5px;
  }
  
  .button-link {
    display: inline-block;
    background-color: #333333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    text-decoration: none;
    margin-right: 10px;
  }
  
  .button-link:hover {
    background-color: #555555;
  }
  
  .edit-btn, .delete-btn {
    background-color: #333333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .edit-btn:hover, .delete-btn:hover {
    background-color: #555555;
  }
  
  .delete-btn {
    background-color: red;
  }
  
  .delete-btn:hover {
    background-color: darkred;
  }
  