/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Container */
  .login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #333333; /* Dark background */
    padding: 20px;
  }
  
  /* Logo */
  .login-logo {
    width: 350px;
    height: auto;
    margin-bottom: 40px;
  }
  
  /* Error Message */
  .error-message {
    color: #ff5a00; /* Brand accent color for errors */
    margin-bottom: 20px;
  }
  
  /* Form */
  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    gap: 20px;
  }
  
  /* Inputs */
  .login-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 2px solid #555555;
    border-radius: 5px;
    background-color: #444444;
    color: #ffffff;
  }
  
  .login-input::placeholder {
    color: #bbbbbb;
  }
  
  /* Button */
  .login-button {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    background-color: #F3A712; /* Brand yellow */
    color: #ffffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #d98f00; /* Darker shade of yellow on hover */
  }