/* Styling for the grid layout */
.group-training-grid {
  display: grid;
  grid-template-columns: 1fr; /* Single column for vertical stacking */
  gap: 20px; /* Spacing between the cells */
  padding: 20px;
}

/* Styling for individual grid cells */
.grid-cell {
  border: 1px solid #ccc;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

/* Styling for client name and other info */
.grid-cell h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.grid-cell p {
  margin: 5px 0;
}

/* Styling for the button */
.grid-cell button {
  padding: 10px 15px;
  background-color: #333333; /* Use #333333 as the shade of black */
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  margin-top: 10px;
  text-align: left;
}

.grid-cell button:hover {
  background-color: #1a1a1a; /* Darker shade of #333333 for hover */
}

/* Styling for the exercise table */
.exercise-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  text-align: left;
}

/* Table header styling */
.exercise-table th {
  background-color: #333333; /* Default header background color */
  color: white;
  font-weight: bold;
  padding: 8px;
  text-align: center;
}

/* Merged header for Workout */
.exercise-table th.workout-header {
  background-color: #333333; /* Dark background */
  color: white;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
}

/* Yellow header for second row (Equipment, Exercise, etc.) */
.exercise-table th.sets-header, 
.exercise-table th.reps-header, 
.exercise-table th.equipment-header, 
.exercise-table th.exercise-header, 
.exercise-table th.lbs-header {
  background-color: #F6AE4B; /* Yellow background */
  color: white; /* White text */
  font-weight: bold;
  padding: 8px;
  text-align: center;
}

/* Table body cells */
.exercise-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Alternating row colors for the table body */
.exercise-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9; /* Very faint gray for odd rows */
}

.exercise-table tbody tr:nth-child(even) {
  background-color: #f4f4f4; /* Fainter gray for even rows */
}

/* Input fields in the table */
.exercise-table input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}

/* Client header section with dropdown and collapse button */
.client-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Content alignment for client info */
.client-content {
  text-align: left; /* Align all text content to the left */
  margin-top: 10px;
}

/* Toggle button for expanding and collapsing client content */
.toggle-button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #333333; /* Use #333333 as the shade of black */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #1a1a1a; /* Darker shade of #333333 for hover */
}

/* Styling for the table */
.exercise-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
  text-align: left;
}

/* First row with Workout label */
.exercise-table .workout-title {
  background-color: #333333;
  color: white;
  font-weight: bold;
  text-align: left;
  padding: 8px;
}

/* Second row with headers */
.exercise-table .header-row {
  background-color: #F6AE4B;
  color: white;
  text-align: left;
  font-weight: bold;
}

/* Table header and body styling */
.exercise-table th, .exercise-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

/* Alternate row styling for exercise rows */
.exercise-table tbody tr:nth-child(odd) {
  background-color: #f9f9f9;
}

.exercise-table tbody tr:nth-child(even) {
  background-color: #f4f4f4;
}

/* Input styling */
.exercise-table input {
  width: 60px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  text-align: center;
}
/* Client header section with dropdown, collapse button, and view profile button */
.client-header {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
}

.client-header-buttons {
  display: flex;
  gap: 10px; /* Space between the buttons */
}

/* Toggle button for expanding and collapsing client content */
.toggle-button {
  padding: 5px 10px;
  background-color: #333333; /* Use #333333 as the shade of black */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.toggle-button:hover {
  background-color: #1a1a1a; /* Darker shade for hover */
}

/* Styling for the "View Profile" button */
.view-profile-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.view-profile-button:hover {
  background-color: #0056b3;
}