button {
    background-color: #333333; /* Darker black color */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 10px;
}

button:hover {
    background-color: #666666; /* Slightly lighter shade for hover effect */
}
