/* ProgramManager.css */

/* General layout for the Program Manager */
.program-manager-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.program-manager-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.program-manager-header h2 {
    font-size: 24px;
    color: #333;
    margin: 0;
}

.program-manager-actions {
    display: flex;
    gap: 10px;
}

.program-manager-actions button {
    background-color: #333333;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.program-manager-actions button:hover {
    background-color: #555555;
}

/* Program List Table */
.program-list {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.program-list th, .program-list td {
    padding: 10px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.program-list th {
    background-color: #f0f0f0;
    color: #333;
    font-weight: 600;
}

.program-list tr:hover {
    background-color: #f5f5f5;
}

/* Buttons inside program list */
.program-list .edit-btn, .program-list .delete-btn {
    background-color: #333333;
    color: white;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.program-list .edit-btn:hover, .program-list .delete-btn:hover {
    background-color: #666666;
}

/* Form and Inputs */
.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    font-size: 16px;
    color: #333;
    margin-bottom: 8px;
}

.form-group input, .form-group select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: white;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Program Creation Form */
.program-creation-form {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Form buttons */
.program-creation-form button {
    background-color: #333333;
    color: white;
    padding: 10px 15px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.program-creation-form button:hover {
    background-color: #555555;
}

