/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* Navbar */
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 40px;
    background-color: #1e1e1e;
  }
  
  .nav-links a {
    margin-left: 30px;
    text-decoration: none;
    color: #ffffff;
  }
  
  .logo {
    font-size: 24px;
    color: #ffffff;
  }
  
  .cta-button {
    padding: 10px 20px;
    background-color: #F3A712; /* Brand yellow */
    color: #ffffff;
    border-radius: 5px;
    text-decoration: none;
    font-weight: bold;
    margin-left: 20px;
  }
  
  /* Hero Section */
  .hero {
    background-color: #333333;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 60px 100px;
    color: #ffffff;
    height: 80vh;
  }
  
  .hero-content {
    flex: 1;
  }
  
  .hero-content h1 {
    font-size: 3rem;
    margin-bottom: 20px;
  }
  
  .hero-content p {
    font-size: 1.2rem;
    margin-bottom: 30px;
  }
  
  .hero-buttons {
    display: flex;
    gap: 20px;
  }
  
  .secondary-button {
    background-color: #e0e0e0;
    color: #333333;
    padding: 12px 24px;
    border-radius: 5px;
    text-decoration: none;
  }
  
  .hero-image {
    flex: 0.5;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  
  .hero-image img {
    max-height: 70vh;
    width: auto;
    border-radius: 10px;
  }
  
  /* How It Works Section */
  .how-it-works {
    background-color: #f1f1f1;
    padding: 80px 100px;
    text-align: center;
  }
  
  .how-it-works h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  
  .steps {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
  
  .step h3 {
    font-size: 1.8rem;
    margin-bottom: 15px;
  }
  
  .step p {
    font-size: 1rem;
  }
  
  /* Benefits Section */
  .benefits {
    background-color: #e9e9e9; /* Slightly darker background */
    padding: 80px 100px;
    text-align: center;
  }
  
  .benefits h2 {
    font-size: 2.5rem;
    margin-bottom: 40px;
  }
  
  .benefits ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    max-width: 600px;
  }
  
  .benefits li {
    margin-bottom: 20px;
    font-size: 1.2rem;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .hero {
      flex-direction: column;
      padding: 40px 20px;
      height: auto;
    }
  
    .hero-content h1 {
      font-size: 2.2rem;
    }
  
    .hero-content p {
      font-size: 1rem;
    }
  
    .how-it-works, .benefits {
      padding: 60px 20px;
    }
  
    .steps {
      gap: 30px;
    }
  }