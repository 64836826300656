.fms-form-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
}

h2 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
}

form {
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 8px;
    border: 1px solid #dddddd;
}

/* Success banner */
.success-banner {
    color: green;
    margin-top: 10px;
    font-weight: bold;
}

/* Table Styling */
.evaluation-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.evaluation-table th,
.evaluation-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
}

.evaluation-table thead {
    background-color: #333333; /* Yellow accent */
    color: white;
}

.evaluation-table tbody tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray for alternating rows */
}

.evaluation-table tbody tr:nth-child(odd) {
    background-color: #eeeeee; /* Slightly darker light gray for odd rows */
}

.evaluation-table tbody tr:hover {
    background-color: #f1f1f1; /* Hover effect for table rows */
}

/* Specific columns for alignment */
.screen-column {
    width: 32%;
    font-weight: bold;
}

.entry-column {
    width: 28%;
}

.prelim-score-column, 
.func-score-column {
    width: 15%;
    text-align: center;
}

/* Unilateral Entry */
.unilateral-entry {
    display: flex;
    justify-content: space-between;
}

.unilateral-entry select,
.unilateral-entry input {
    width: 48%;
}
/* Evaluation Names - Bold */
.evaluation-table td:first-child {
    font-weight: bold;
}
/* Custom dropdown styling */
.evaluation-table select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff; /* White background */
    color: #333; /* Darker text color */
    font-size: 20px;
    appearance: none; /* Hide the default dropdown arrow for custom styling */
    background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23333' d='M2 0L0 2h4z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 12px;
}

/* Change dropdown focus and hover state */
.evaluation-table select:focus,
.evaluation-table select:hover {
    border-color: #F6AE4B; /* Highlight border with yellow accent */
    outline: none;
}
/* Button Styling */
button {
    background-color: #F6AE4B; /* Yellow accent */
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    margin-right: 10px; /* Add space between buttons */
}

button:hover {
    background-color: #333333; /* Darker hover effect */
}


@media (max-width: 768px) {
    .evaluation-table th, .evaluation-table td {
        padding: 8px;
    }

    .unilateral-entry select,
    .unilateral-entry input {
        width: 45%;
    }
}

/* Ensure unilateral entry fields are side by side */
.unilateral-entry {
    display: flex; /* Use flexbox to align items in one row */
    gap: 10px; /* Add space between the two dropdowns */
    justify-content: center; /* Center the dropdowns */
    align-items: center;
}
/* Adjust flexbox for narrower screens (responsive design) */
@media (max-width: 768px) {
    .unilateral-entry {
        flex-direction: column; /* Stack dropdowns vertically for small screens */
    }
}

/* Style for input fields */
.evaluation-table input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff; /* White background */
    color: #333; /* Darker text color */
    font-size: 20px; /* Match the font size of the dropdowns */
}

/* Adjust Preliminary Score cell styles */
.prelim-score-column {
    text-align: left; /* Align text to the left */
    padding: 12px; /* Add more padding for better spacing */
    white-space: nowrap; /* Ensure content stays in one line if necessary */
}

.prelim-score-column span {
    display: block; /* Stack Left/Right on separate lines */
    font-weight: bold; /* Make Left/Right labels bold */
}

.prelim-score-column span.score {
    font-weight: normal; /* Ensure the actual score remains normal weight */
    margin-left: 10px; /* Add some space between label and score */
}

/* Add more spacing between Left and Right scores */
.prelim-score-column span {
    margin-bottom: 4px; /* Space between left and right labels */
}
/* Evaluation Date styling */
.evaluation-date {
    font-weight: bold;
    font-size: 20px; /* Match the size of other elements */
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.evaluation-date label {
    margin-right: 10px;
}

.evaluation-date input[type="date"] {
    font-family: Arial, sans-serif; /* Match the font with other elements */
    font-size: 18px; /* Adjust font size */
    padding: 5px; /* Padding inside the date input */
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #ffffff;
    color: #333;
    max-width: 200px; /* Set an appropriate max width */
    cursor: pointer;
}

.evaluation-date input[type="date"]:focus {
    border-color: #F6AE4B; /* Highlight border with yellow accent */
    outline: none;
}

/* Ensuring the native calendar icon appears */
.evaluation-date input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
}

