.client-exercise-record {
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .exercise-record-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #333;
  }
  
  .exercise-record-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .exercise-record-table th,
  .exercise-record-table td {
    padding: 12px 15px; /* Make it more spacious */
    text-align: left;
    border-bottom: 1px solid #ddd;
    vertical-align: top; /* Ensure data aligns well */
  }
  
  .exercise-record-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    font-size: 18px;
    color: #333;
  }
  
  .exercise-record-table td {
    font-size: 16px;
    color: #555;
  }
  
  .exercise-record-table tbody tr:nth-child(even) {
    background-color: #f7f7f7; /* Alternate row colors */
  }
  
  .exercise-record-table tbody tr:hover {
    background-color: #eaeaea; /* Hover effect */
  }
  
  .exercise-record-table td div {
    margin-bottom: 5px;
  }
  
  .exercise-record-table td div:last-child {
    margin-bottom: 0;
  }
  